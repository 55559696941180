import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#15ad69",
      },
      dark: {
        primary: "#15ad69",
        secondary: "#6d838a",
      },
    },
    dark: false,
  },
});
